<template>
  <div>
    <v-form ref="form" v-model="validForm" v-if="item" lazy-validation>
      <v-row class="my-4 mb-6">
        <v-col cols="12" md="4" sm="5" class="py-0 px-5">
          <h3>Datos de Usuario</h3>
          <v-divider class="mt-1 mb-5"></v-divider>
          <v-row>
            <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Usuario"
                :disabled="typeForm == 'E'"
                :loading="showLoadingUsuario"
                @blur="verificarExistenciaUsuario"
                :rules="[rules.required, rulesExisteUsuario, rules.minMax(item.idusuario, 4, 20), rules.alfabetico]"
                @input="item.idusuario = $event.toUpperCase()"
                v-model="item.idusuario"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
              <v-select
                label="Rol"
                dense
                :rules="[rules.required]"
                v-model="item.role"
                :items="listadoRoles"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Contraseña"
                :disabled="typeForm == 'E'"
                :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showContraseña ? 'text' : 'password'"
                @click:append="showContraseña = !showContraseña"
                :rules="typeForm == 'N' ? [rules.required, rules.numerico, rules.minMax(item.password, 6, 16)] : []"
                v-model="item.password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Confirmación Contraseña"
                :disabled="typeForm == 'E'"
                :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showContraseña ? 'text' : 'password'"
                @click:append="showContraseña = !showContraseña"
                :rules="
                  typeForm == 'N'
                    ? [
                        rules.required,
                        rules.numerico,
                        rules.minMax(item.rulesConfirmPassword, 6, 16),
                        rulesConfirmPassword
                      ]
                    : []
                "
                v-model="item.confirmPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <!--<v-card elevation="0" outlined width="100%">
            <v-card-title class="py-1">Datos de Usuario</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-4 row">
              <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Usuario"
                  :disabled="typeForm == 'E'"
                  :loading="showLoadingUsuario"
                  @blur="verificarExistenciaUsuario"
                  :rules="[rules.required, rulesExisteUsuario, rules.minMax(item.idusuario, 4, 20), rules.alfabetico]"
                  @input="item.idusuario = $event.toUpperCase()"
                  v-model="item.idusuario"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
                <v-select
                  label="Rol"
                  dense
                  :rules="[rules.required]"
                  v-model="item.role"
                  :items="listadoRoles"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Contraseña"
                  :disabled="typeForm == 'E'"
                  :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showContraseña ? 'text' : 'password'"
                  @click:append="showContraseña = !showContraseña"
                  :rules="typeForm == 'N' ? [rules.required, rules.numerico, rules.minMax(item.password, 6, 16)] : []"
                  v-model="item.password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Confirmación Contraseña"
                  :disabled="typeForm == 'E'"
                  :append-icon="showContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showContraseña ? 'text' : 'password'"
                  @click:append="showContraseña = !showContraseña"
                  :rules="
                    typeForm == 'N'
                      ? [
                          rules.required,
                          rules.numerico,
                          rules.minMax(item.rulesConfirmPassword, 6, 16),
                          rulesConfirmPassword
                        ]
                      : []
                  "
                  v-model="item.confirmPassword"
                ></v-text-field>
              </v-col>
            </v-card-text>
          </v-card>-->
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="8" sm="7" class="py-0 px-5">
          <h3>Datos de Responsable</h3>
          <v-divider class="mt-1 mb-5"></v-divider>
          <v-row>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Documento de Identidad"
                :disabled="typeForm == 'E'"
                :loading="showLoadingDNI"
                @blur="verificarExistenciaDNI"
                :rules="[rules.required, rulesExisteDNI, rules.minMax(item.idtrabajador, 8, 12), rules.numerico]"
                v-model="item.idtrabajador"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Nombre Completo"
                @input="item.nombresall = $event.toUpperCase()"
                :rules="[rules.required, rules.minMax(item.nombresall, 3, 100), rules.alfabeticoTildes]"
                v-model="item.nombresall"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-select
                label="Tipo Responsable"
                dense
                :rules="[rules.required]"
                v-model="item.idtiporesponsable"
                :items="listadoTiposResponsables"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-autocomplete
                :items="listadoSubAreas"
                dense
                v-model="item.idsubarea"
                :return-object="false"
                :rules="[rules.required]"
                label="Sub Área"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-menu
                ref="menuFI"
                dense
                v-model="menuFI"
                :close-on-content-click="false"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.fechainicio"
                    :rules="[rules.fechaInicio(item.fechainicio, item.fechafin)]"
                    dense
                    readonly
                    label="Fecha Inicio"
                    persistent-hint
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es-es"
                  :first-day-of-week="1"
                  v-model="item.fechainicio"
                  no-title
                  @input="menuFI = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
              <v-menu
                ref="menuFF"
                dense
                v-model="menuFF"
                :close-on-content-click="false"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.fechafin"
                    :rules="[rules.fechaFin(item.fechafin, item.fechainicio)]"
                    dense
                    readonly
                    label="Fecha Fin"
                    persistent-hint
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es-es"
                  :first-day-of-week="1"
                  v-model="item.fechafin"
                  no-title
                  @input="menuFF = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0 px-3 mt-2">
              <v-text-field
                dense
                label="Email"
                :suffix="emailDefault ? '@gmail.com' : ''"
                :rules="
                  emailDefault
                    ? [rules.required, rules.emailPart, rules.minMax(item.email, 1, 181)]
                    : [rules.required, rules.email, rules.minMax(item.email, 1, 200)]
                "
                :append-icon="emailDefault ? 'mdi-close' : 'mdi-replay'"
                @click:append="changeEmailDefault"
                v-model="item.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <!--<v-card elevation="0" outlined width="100%">
            <v-card-title class="py-1">Datos de Responsable</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-4 row">
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Documento de Identidad"
                  :disabled="typeForm == 'E'"
                  :loading="showLoadingDNI"
                  @blur="verificarExistenciaDNI"
                  :rules="[rules.required, rulesExisteDNI, rules.minMax(item.idtrabajador, 8, 12), rules.numerico]"
                  v-model="item.idtrabajador"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Nombre Completo"
                  @input="item.nombresall = $event.toUpperCase()"
                  :rules="[rules.required, rules.minMax(item.nombresall, 3, 100), rules.alfabeticoTildes]"
                  v-model="item.nombresall"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-select
                  label="Tipo Responsable"
                  dense
                  :rules="[rules.required]"
                  v-model="item.idtiporesponsable"
                  :items="listadoTiposResponsables"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-autocomplete
                  :items="listadoSubAreas"
                  dense
                  v-model="item.idsubarea"
                  :return-object="false"
                  :rules="[rules.required]"
                  label="Sub Área"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-menu
                  ref="menuFI"
                  dense
                  v-model="menuFI"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.fechainicio"
                      :rules="[rules.fechaInicio(item.fechainicio, item.fechafin)]"
                      dense
                      readonly
                      label="Fecha Inicio"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-es"
                    :first-day-of-week="1"
                    v-model="item.fechainicio"
                    no-title
                    @input="menuFI = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="py-0 px-3 mt-2">
                <v-menu
                  ref="menuFF"
                  dense
                  v-model="menuFF"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.fechafin"
                      :rules="[rules.fechaFin(item.fechafin, item.fechainicio)]"
                      dense
                      readonly
                      label="Fecha Fin"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-es"
                    :first-day-of-week="1"
                    v-model="item.fechafin"
                    no-title
                    @input="menuFF = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-0 px-3 mt-2">
                <v-text-field
                  dense
                  label="Email"
                  :suffix="emailDefault ? '@gmail.com' : ''"
                  :rules="
                    emailDefault
                      ? [rules.required, rules.emailPart, rules.minMax(item.email, 1, 181)]
                      : [rules.required, rules.email, rules.minMax(item.email, 1, 200)]
                  "
                  :append-icon="emailDefault ? 'mdi-close' : 'mdi-replay'"
                  @click:append="changeEmailDefault"
                  v-model="item.email"
                ></v-text-field>
              </v-col>
            </v-card-text>
          </v-card>-->
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-5">
        <v-col cols="12" md="7" sm="12" class="py-0 px-3">
          <v-card elevation="0" outlined width="1000" height="100%" class="text-left">
            <v-card-title class="py-1">Menú</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 330px; overflow-y: scroll;" class="pt-0">
              <v-switch
                v-model="openAll"
                dense
                @change="openTreeviewPermisos"
                :label="`${!openAll ? 'Abrir' : 'Cerrar'}`"
                class="pa-0"
              ></v-switch>
              <v-treeview
                selectable
                selected-color="primary"
                :items="itemsPermisos.menu"
                item-key="idmodulo"
                selection-type="leaf"
                v-model="menuSelected"
                ref="treeview"
                :open-all="openAll"
                style="margin-top: -20px;"
                return-object
              >
                <template v-slot:label="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" sm="12" class="py-0 px-3 mt-sm-5 mt-md-0">
          <v-card tile elevation="0" outlined width="1000" height="100%" class="text-left">
            <v-card-title class="py-1">Permisos por Listas</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 330px;" class="pa-0">
              <v-data-table
                v-model="item.listmodulo"
                @item-selected="seleccionListModulo"
                @toggle-select-all="seleccionAllListModulo"
                :headers="headersListModulo"
                :items="itemsPermisos.listmodulo"
                item-key="codigo"
                return-object
                show-select
                dense
                class="border"
                multi-sort
                hide-default-footer
                :items-per-page="-1"
                :mobile-breakpoint="0"
                no-results-text="No se encontraron datos"
                no-data-text="No se encontraron datos"
                :search="search"
                item-class="class"
                :fixed-header="true"
                height="330"
              >
                <template v-slot:[`item.tipoacceso`]="{ item, index }">
                  <v-select
                    dense
                    :rules="selectedModulo(item) ? [rules.required] : []"
                    :disabled="!selectedModulo(item)"
                    v-model="item.tipoacceso"
                    @change="setTipoAcceso($event, index)"
                    hide-details
                    :items="listadoTiposAcceso"
                  ></v-select>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-spacer></v-spacer>
    <div class="d-flex justify-end mt-2">
      <v-btn color="error" text @click="close()"> Cancelar </v-btn>
      <v-btn color="primary" text @click="guardar()"> Aceptar </v-btn>
    </div>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';

export default {
  name: 'UsuarioFormulario',
  props: {
    typeForm: {
      type: String,
      default: 'N'
    }
  },
  watch: {},
  data: () => ({
    emailDefault: true,
    search: '',
    menuFI: false,
    menuFF: false,
    showLoading: false,
    validForm: true,
    idUsuario: null,
    existeUsuario: false,
    errorUsuario: '',
    showLoadingUsuario: false,
    existeDNI: false,
    errorDNI: '',
    showLoadingDNI: false,
    showContraseña: false,
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'E-mail inválido.';
      },
      emailPart: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
        return pattern.test(value) || 'E-mail inválido.';
      },
      minMax: (value, min, max) => {
        if (value) return (value.length >= min && value.length <= max) || `Min: ${min} - Max: ${max} caract.`;
        else return true;
      },
      alfabetico: (value) => {
        const pattern = /^[A-Za-z\s]+$/;
        return pattern.test(value) || 'El campo debe ser alfabético sin tíldes';
      },
      alfabeticoTildes: (value) => {
        const pattern = /^[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ\s]+$/;
        return pattern.test(value) || 'El campo debe ser alfabético';
      },
      numerico: (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || 'El campo debe ser numérico';
      },
      fechaInicio: (value, fechaFin) => {
        if (value && fechaFin) return value <= fechaFin || 'Fecha inicio debe ser menor/igual a fecha fin';
        else return true;
      },
      fechaFin: (value, fechaInicio) => {
        if (value && fechaInicio) return value >= fechaInicio || 'Fecha fin debe ser menor/igual a fecha inicio';
        else return true;
      }
    },
    listado: [],
    item: null,
    listadoTiposResponsables: [],
    listadoSubAreas: [],
    listadoRoles: [
      { value: 'user', text: 'user' },
      { value: 'admin', text: 'admin' }
    ],
    listadoTiposAcceso: [
      { value: 'U', text: 'Usuario' },
      { value: 'A', text: 'Área' },
      { value: 'T', text: 'Todo' }
    ],
    itemsPermisos: [],
    headersListModulo: [
      {
        text: 'T. Acceso',
        value: 'tipoacceso',
        width: '120',
        class: 'primary',
        fixed: true,
        align: 'center'
      },
      {
        text: 'Descripción',
        value: 'descripcion',
        width: '400',
        class: 'primary',
        fixed: true,
        align: 'left'
      }
    ],
    menuSelected: [],
    openAll: false
  }),
  computed: {
    rulesExisteUsuario() {
      return !this.existeUsuario || this.errorUsuario;
    },
    rulesExisteDNI() {
      return !this.existeDNI || this.errorDNI;
    },
    rulesConfirmPassword() {
      return this.item.password == this.item.confirmPassword || 'Las contraseñas no coinciden';
    },
    itemsx() {
      const replaceChildren = (obj, parent) => {
        const clone = Object.assign({}, obj);
        delete clone.children;
        if (parent) clone.parent = parent;
        return clone;
      };

      const addItems = (arr, parent) => {
        const items = arr.reduce((acc, x) => {
          acc.push(replaceChildren(x, parent));

          if (x.children) {
            acc.push(addItems(x.children, x.idmodulo));
          }
          return acc;
        }, []);

        return items.flat();
      };

      if (!this.itemsPermisos.menu) return [];

      return addItems(this.itemsPermisos.menu).reduce((acc, x) => {
        acc[x.idmodulo] = x;
        return acc;
      }, {});
    },
    menuSelectedx() {
      if (!this.itemsx) return [];
      const proxy = {};
      let addParents = (x, all) => {
        const parentId = this.itemsx[x.idmodulo].parent;
        if (parentId) {
          if (all) addParents(this.itemsx[parentId]);
          proxy[parentId] = this.itemsx[parentId];
        }
      };
      this.menuSelected.forEach((x) => {
        addParents(x, true);
        proxy[x.idmodulo] = x;
      });
      return Object.values(proxy);
    }
  },
  methods: {
    async inicializarItem() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.item = {
        idusuario: '',
        role: this.typeForm == 'N' ? 'user' : '',
        password: '',
        confirmPassword: '',
        nombresall: '',
        tiporesponsable: '',
        idtrabajador: '',
        email: '',
        idsubarea: '',
        fechainicio: '',
        fechafin: '',
        listmodulo: [],
        menu: []
      };
    },
    close() {
      this.$router.push({ path: '/permisos/usuarios' });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async guardar() {
      if (this.$refs.form.validate()) {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: '¿Está seguro de guardar los cambios?',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (confirmed.isConfirmed) {
          this.showLoading = true;
          let objeto = {
            ...this.item,
            email: this.emailDefault ? this.item.email + '@gmail.com' : this.item.email,
            idresponsable: this.item.idusuario,
            permisos: this.menuSelectedx,
            modulos: this.item.listmodulo,
            fechainicio: this.item?.fechainicio?.length == 0 ? null : this.item.fechainicio,
            fechafin: this.item?.fechafin?.length == 0 ? null : this.item.fechafin,
            actualizar: this.typeForm == 'E' ? 1 : 0
          };
          const res = await this.service.post().execResource('guardarUsuario', objeto);
          this.showLoading = false;
          this.alertDialog(res.status ? 'success' : 'error', res.data);
          if (res.status) this.close();
        }
      }
    },
    async cargarTiposResponsables() {
      this.listadoTiposResponsables = [];
      const res = await this.service.get().execResource('cargarTipoResponsable');
      this.listadoTiposResponsables = res.map((element) => {
        return { value: element.idtiporesponsable, text: element.descripcion, ...element };
      });
    },
    async cargarSubAreas() {
      this.listadoSubAreas = [];
      const res = await this.service.get().execResource('cargarSubAreas', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      this.listadoSubAreas = res.map((element) => {
        return { value: element.idsubarea, text: element.subarea, ...element };
      });
    },
    async cargarModulos() {
      this.itemsPermisos = [];
      const res = await this.service.get().execResource('cargarModulos', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      this.itemsPermisos = res[0];
      await this.itemsPermisos.listmodulo.map((el) => (el.tipoacceso = ''));
      this.$forceUpdate();
      this.$refs.treeview.updateAll(false);
    },
    changeEmailDefault() {
      this.emailDefault = !this.emailDefault;
      let indice = this.item.email.indexOf('@');
      this.item.email =
        this.emailDefault && indice > 0 ? this.item.email.substring(0, this.item.email.indexOf('@')) : this.item.email;
    },
    async verificarUsuario(tipo) {
      return await this.service.get().execResource('verificarUsuario', {
        usuario: tipo == 'U' ? this.item.idusuario : '',
        idtrabajador: tipo == 'D' ? this.item.idtrabajador : ''
      });
    },
    async verificarExistenciaUsuario() {
      this.showLoadingUsuario = true;
      const res = await this.verificarUsuario('U');
      this.existeUsuario = res[0].existe == '1' ? true : false;
      this.errorUsuario = res[0].mensaje;
      this.showLoadingUsuario = false;
    },
    async verificarExistenciaDNI() {
      this.showLoadingDNI = true;
      const res = await this.verificarUsuario('D');
      this.existeDNI = res[0].existe == '1' ? true : false;
      this.errorDNI = res[0].mensaje;
      this.showLoadingDNI = false;
    },
    selectedModulo(item) {
      let find = this.item.listmodulo.find((el) => el.codigo === item.codigo);
      return find;
    },
    seleccionListModulo(item) {
      let pos = this.itemsPermisos.listmodulo.findIndex((el) => el.codigo === item.item.codigo);
      if (item.value) this.itemsPermisos.listmodulo[pos].tipoacceso = 'U';
      else this.itemsPermisos.listmodulo[pos].tipoacceso = '';
      /*if (item.value) item.item.tipoacceso = 'U';
      else item.item.tipoacceso = '';*/
    },
    seleccionAllListModulo(item) {
      if (item.value)
        this.itemsPermisos.listmodulo.map((el) => (el.tipoacceso == '' ? (el.tipoacceso = 'U') : el.tipoacceso));
      else this.itemsPermisos.listmodulo.map((el) => (el.tipoacceso = ''));
    },
    async cargarDatosUsuario() {
      const res = await this.service.get().execResource('cargarDatosUsuario', {
        usuario: this.idUsuario
      });
      return res[0];
    },
    async llenarTiposPermisos() {
      this.item.listmodulo.forEach((element) => {
        let pos = this.itemsPermisos.listmodulo.findIndex((el) => el.codigo === element.codigo);
        this.itemsPermisos.listmodulo[pos].tipoacceso = element.tipoacceso;
      });
    },
    async llenarDatos() {
      this.idUsuario = this.$route.query.id;
      if (this.idUsuario) {
        let item = await this.cargarDatosUsuario();
        if (this.typeForm == 'E') {
          this.emailDefault = item.email.includes('@gmail.com');
          item.email = this.emailDefault ? item.email.replace('@gmail.com', '') : item.email;
          this.item = { ...item, listmodulo: item.tipopermisos, menu: item.permisos };
        } else {
          this.item = { ...this.item, listmodulo: item.tipopermisos, menu: item.permisos };
        }
        this.menuSelected = item.permisos;
        this.$forceUpdate();
        this.$refs.treeview.updateAll(false);
        await this.llenarTiposPermisos();
      }
    },
    async openTreeviewPermisos() {
      this.$forceUpdate();
      this.$refs.treeview.updateAll(this.openAll);
    },
    async setTipoAcceso(event, index) {
      this.item.listmodulo = await this.item.listmodulo.map((el) => {
        if (el.codigo == this.itemsPermisos.listmodulo[index].codigo) {
          el.tipoacceso = event;
        }
        return el;
      });
      this.itemsPermisos.listmodulo[index].tipoacceso = event;
    }
  },
  async created() {
    this.showLoading = true;
    this.service = this.$httpService(new MatrizService(), this);
    await this.cargarTiposResponsables();
    await this.inicializarItem();
    await this.cargarSubAreas();
    await this.cargarModulos();
    await this.llenarDatos();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.background-total {
  background-color: red;
}

.tree-one-leve > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__level {
  width: 0px !important;
}
</style>
