class MatrizService {
  constructor() {}
  resources = () => ({
    listarUsuarios: {
      uri: `/v1/autorizacion/listar-usuarios`,
      method: ['get']
    },
    cargarTipoResponsable: {
      uri: `/v1/autorizacion/listar-tipo-responsables`,
      method: ['get']
    },
    cargarSubAreas: {
      uri: `/v1/subareas`,
      method: ['get']
    },
    cargarModulos: {
      uri: `/v1/autorizacion/listar-modulos`,
      method: ['get']
    },
    cargarDatosUsuario: {
      uri: `/v1/autorizacion/permisos-usuario`,
      method: ['get']
    },
    verificarUsuario: {
      uri: `/v1/autorizacion/check-usuario`,
      method: ['get']
    },
    cambiarEstado: {
      uri: `/v1/autorizacion/activar-desactivar-usuario`,
      method: ['post']
    },
    exportarUsuarios: {
      uri: `/v1/exportar-usuarios`,
      method: ['get']
    },
    guardarUsuario: {
      uri: `/v1/autorizacion/insertar-actualizar-usuario`,
      method: ['post']
    },
    cambiarClave: {
      uri: `/v1/autorizacion/cambiar-contrasenia-usuario`,
      method: ['post']
    }
  });
}

export default MatrizService;
